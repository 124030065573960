import { colors, fonts } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .buttons-container {
    margin-top: 2rem;
  }

  .slick-arrow {
    background-color: black;
  }
  .slick-arrow:hover {
    background-color: black;
  }

  .valuation-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

/*export const pageIndicator = css`
  .page-indicator-container {
    margin: 0;
    margin-bottom: 1.5rem;
    width: 100%;
  }
`*/

export const common = css.global`
  .step-content {
    width: 100%;
  }
  
  .header, .sub-header {
    font: ${fonts.bold};
    color: ${colors.primary};
    font-size: 24px;
    margin: 5px 0;
  }
  
  .sub-header {
    margin-bottom: 30px;
  }
  
  .header.centered, .sub-header.centered {
    text-align: center;
  }
  
  .sub-header {
    font-size: 20px;
  }

  .btn-search-cp {
    height: 2.1rem !important;
    width: 100%;
    margin-top: 1rem;
  }
  
  .exact-location-section {
    margin-top: 2rem;
  }
  .chk-exact-location {
    display: inline-block;
  }
`

export const valuationAppointment = css`
  .valuation-appointment-container {
    max-width: 550px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (max-width: 772px) {
    .valuation-appointment-container {
      max-width: 320px;
    }
  }
`

export const valuationConfirmation = css`
  .confirmation-container {
    text-align: center;
    color: ${colors.secondary};
    /*display: flex;
    flex-direction: column;*/
  }
  .email,
  .telephone {
    font: ${fonts.semiBold};
    font-size: 14px;
  }
  .normal {
    font: ${fonts.medium};
    font-size: 14px;
  }
  .broker {
    font: ${fonts.medium};
    font-size: 16px;
    color: ${colors.primary};
  }
  .contact {
    font: ${fonts.medium};
    font-size: 12px;
    max-width: 200px;
    margin: 0 auto;
  }
  .line {
    background-color: ${colors.borderInput};
    width: 100%;
    max-width: 540px;
    height: 1px;
    border-radius: 4px;
    margin: 20px auto;
  }
`

export const propertyLocationStyles = css`
  .header {
    text-align: center;
  }
  .characteristics-title {
    margin-bottom: 0.5rem;
  }

  .states-labels {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: flex-end;
  }
  .states-labels > :global(.label) {
    margin: 0 0.2rem 0.2rem 0;
  }
`